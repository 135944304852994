<mat-card appearance="outlined" class="mat-mr mr-accent">
    <mat-card-title class="titleRow">
        <span>{{tableName()}}</span>
        <span class="mr-auto">
            @if(aggregateTable().dataUrl != "ebd"){
                <mat-select [(ngModel)]="selectedCommunity" >
                    <mat-option *ngFor="let item of communityList" [value]="item">
                        {{item.nameDisplay}}
                    </mat-option>
                </mat-select>
            }
        </span>
    </mat-card-title>
    <mat-card-title class="lookupDescription">
        <span class="subText">({{description()}})</span>
    </mat-card-title>
    <mat-card-content>
        <!-- <search-input [(filter)]="requestData" #searchInput></search-input> -->
        <div infiniteScroll [onScroll]="onScroll" [filter]="requestData()">
            <load-spinner variant="table"></load-spinner>
            <table mat-table [dataSource]="dataSource" class="mat-mr" [trackBy]="trackRecord">
        <!-- <div>
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear class="mat-mr"> -->
                <ng-container [matColumnDef]="col.prop" *ngFor="let col of cols()">
                    <th mat-header-cell *matHeaderCellDef id="col.prop"> {{col.name}} </th>
                    <td mat-cell *matCellDef="let item">
                        @if(col.isDate){
                            <div class="multiline">
                                @if(col.dataType == "full"){
                                    <span>
                                        {{item[col.prop] | date : 'shortDate'}}
                                    </span>
                                    <span class="subText">
                                        {{item[col.prop] | date : 'shortTime'}}
                                    </span>
                                }@else if(col.dataType == "year"){
                                    <span>
                                        {{item[col.prop] | date : 'yyyy'}}
                                    </span>
                                }@else if(col.dataType == "yearMonth"){
                                    <span>
                                        {{item[col.prop] | date : 'MM/yyyy'}}
                                    </span>
                                }@else {
                                    <span>
                                        {{item[col.prop] | date : 'MM/dd/yyyy'}}
                                    </span>
                                }
                            </div>
                        }@else if(col.dataType == 'list') {
                            <div class="multiline">
                                @if(item.prospectStages){
                                    @for(stage of item.prospectStages; track stage.stage){
                                        <div class="subText no-overflow">{{stage.stage}}:&nbsp;{{stage.count}}</div>
                                    }
                                }@else {
                                    <div class="subText">No Stage Data</div>
                                }
                            </div>
                        }@else {
                            {{item[col.prop]}}
                        }
                    </td>
                </ng-container>                
                <tr mat-header-row *matHeaderRowDef="displayedCols()"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedCols();"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedCols().length">
                        <div>
                            There are currently no items in this list
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </mat-card-content>
    
</mat-card>